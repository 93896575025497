/**
 * 导入数据对应表
 */
export const USER_RELATIONS = {
  学号: 'num',
  学生姓名: 'name',
  学生身份证号: 'id_card',
  系部: 'collegeName',
  专业: 'domain',
  年级: 'grade',
  入学年份: 'year',
  班主任姓名: 'teacher',
  备注: 'remark',
  班级: 'className',
  层次ID: 'length'

  // className: "二班"
  // collegeName: "汽修学院"
  // create_time: "2022-07-20 14:32:31"
  // domain: "汽车维修"
  // grade: "2022级"
  // id_card: "370784202207200002"
  // is_del: 0
  // length: "1616133471239"
  // level_name: "中级(三年)"
  // name: "测试修改"
  // num: "202207200002"
  // remark: "无"
  // teacher: "王老师"
  // year: 2022
}

/**
 * 解析 excel 导入的时间格式
 */
export const formatDate = (numb) => {
  const time = new Date((numb - 1) * 24 * 3600000 + 1)
  time.setYear(time.getFullYear() - 70)
  const year = time.getFullYear() + ''
  const month = time.getMonth() + 1 + ''
  const date = time.getDate() - 1 + ''
  return (
    year +
    '-' +
    (month < 10 ? '0' + month : month) +
    '-' +
    (date < 10 ? '0' + date : date)
  )
}
