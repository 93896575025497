import request from '@/utils/request'

// 老生列表
export const listOldStudentAPI = (params) => {
  return request({
    url: '/studnetList',
    method: 'GET',
    params
  })
}

// 老生删除
export const deleteOldStudentAPI = (params) => {
  return request({
    url: '/studnetDel',
    method: 'GET',
    params
  })
}

// 详情
export const studentDetailAPI = (params) => {
  return request({
    url: '/studnetInfo',
    method: 'GET',
    params
  })
}

// 修改
export const editStudentAPI = (data) => {
  return request({
    url: '/studnetSave',
    method: 'POST',
    data
  })
}

// 导入
export const ExcelImportAPI = (dataset) => {
  return request({
    url: '/excel_import',
    method: 'POST',
    data: {
      dataset
    }
  })
}

// 详情
export const errorImportAPI = (params) => {
  return request({
    url: '/errorImport',
    method: 'GET',
    params
  })
}

// 导出
export const reportErrorAPI = (params) => {
  return request({
    url: '/reportError',
    method: 'GET',
    params
  })
}
